<div class="d-flex flex-column h-100">
  <div class="container my-auto">
    <div class="d-flex flex-column align-items-center">
      <h1 class="text-center my-4">
        {{ 'contactManager.contactManagerTitle' | translate }}
      </h1>
      <svg data-name="Layer 1" width="815.79361" height="574.50844" viewBox="0 0 815.79361 574.50844">
        <circle cx="406.79361" cy="238.5" r="26" fill="#2390ea" />
        <path
          d="M314.89681,196.93578v247.62a22.71737,22.71737,0,0,0,22.69,22.69h647.62a22.71738,22.71738,0,0,0,22.69-22.69v-247.62a22.71738,22.71738,0,0,0-22.69-22.69h-647.62A22.71737,22.71737,0,0,0,314.89681,196.93578Zm2,0a20.69,20.69,0,0,1,20.69-20.69H850.22126a155.67555,155.67555,0,0,1,155.67555,155.67554V444.55578a20.69,20.69,0,0,1-20.69,20.69h-647.62a20.69,20.69,0,0,1-20.69-20.69Z"
          transform="translate(-192.10319 -162.74578)"
          fill="#3f3d56"
        />
        <polygon
          points="130.261 555.88 144.768 555.879 151.669 499.923 130.258 499.925 130.261 555.88"
          fill="#ffb6b6"
        />
        <path
          d="M321.85331,733.75953l44.61057-.0017v-.56411a17.36462,17.36462,0,0,0-17.36368-17.36341h-.00107l-8.14867-6.182-15.20364,6.183-3.89422.00009Z"
          transform="translate(-192.10319 -162.74578)"
          fill="#2f2e41"
        />
        <polygon points="66.979 555.88 81.486 555.879 88.387 499.923 66.976 499.925 66.979 555.88" fill="#ffb6b6" />
        <path
          d="M258.57178,733.75953l44.61057-.0017v-.56411a17.36463,17.36463,0,0,0-17.36368-17.36341h-.00107l-8.14867-6.182-15.20364,6.183-3.89423.00009Z"
          transform="translate(-192.10319 -162.74578)"
          fill="#2f2e41"
        />
        <path
          d="M358.4847,413.73855s13.95767,42.86862,11.38135,48.51083-6.97188,1.24666-2.57632,5.64222,8.23265-.27689,4.11632,7.18749-10.3524,68.91369-13.09511,71.88833-6.51375,3.05444-3.163,5.94491,7.74627-2.97028,3.35071,2.89047-16.05937,141.06171-16.05937,141.06171H319.65391s-5.67493-6.54142-2.46254-10.79854-.282-5.89808,0-8.74057-.78643-8.971-.9848-11.76748-4.0491-69.417,5.20219-107.90088l-16.86958-73.27716L284.1044,572.94511s9.39994,74.28765,0,98.169c0,0,7.57843,6.31069,4.2174,9.96789s-6.2914.72682-3.361,3.6572-4.39556,13.18668-4.39556,13.18668L255.657,696.46072,241.55894,557.657s-2.3766-14.47561,0-16.95605,2.3766,6.31069,2.3766-2.48043v-11.7215s1.37658-72.0941,1.56014-76.33968,17.76579-35.01451,17.76579-35.01451Z"
          transform="translate(-192.10319 -162.74578)"
          fill="#2f2e41"
        />
        <path
          d="M358.27681,265.67577v84.53a81.249,81.249,0,0,0-4.17,25.38c.09,14.37,3.25,30.93,3.24,38.17a9.45671,9.45671,0,0,1-.31,2.85c-13.99,40.55-88.02,3.7-94.75.25-.32-.16-.49-.25-.49-.25l1.46-45.42-.25-13.06-1.73-89.59,1.55-1.24,13.58-10.83,1.33-1.06,4.08,5.95,36.37-2.5,13.88-3.84,4.33,1.76,19.52,7.94Z"
          transform="translate(-192.10319 -162.74578)"
          fill="#2390ea"
        />
        <path
          d="M485.0315,332.10117l-25.48732-8.42665L452.699,337.56271l27.26378,7.95039a8.98633,8.98633,0,1,0,5.06873-13.41193Z"
          transform="translate(-192.10319 -162.74578)"
          fill="#ffb6b6"
        />
        <path
          d="M333.58807,257.45351l-.1056.42189c-1.99167,7.9676-4.53442,30.72543.91434,36.87038,6.997,7.901,63.51687,32.02112,74.745,30.056,8.70858-1.52413,6.91566-1.01243,11.08222,3.32234s-2.74914,5.34721,6.545,6.10572,9.47947-2.80053,11.47006,1.14635.21963,6.32533,6.545,6.10572,7.911.961,10.0869,1.34887S470.71,349.695,470.71,349.695s5.48109-15.63659,12.37961-21.59122c0,0-15.82194-1.922-17.01973-5.2786s-.21963-6.32537-4.369-5.71789-6.30823,5.16186-5.73505-.57319S426.649,299.63437,426.649,299.63437C408.61047,281.87419,371.54924,269.53562,333.58807,257.45351Z"
          transform="translate(-192.10319 -162.74578)"
          fill="#2390ea"
        />
        <path
          d="M252.72341,433.99657l-1.15612-26.81931-15.41287-1.47713,2.23122,28.31155a8.98634,8.98634,0,1,0,14.33777-.01511Z"
          transform="translate(-192.10319 -162.74578)"
          fill="#ffb6b6"
        />
        <path
          d="M268.83485,265.92573l-.43192.05082A24.89043,24.89043,0,0,0,249.276,279.13581c-4.90733,9.34358-20.43214,72.62753-16.63084,81.31222,3.545,8.0991,3.39835,6.10755.8222,11.54022s-5.97452-.675-3.38892,8.28443,5.9792,7.871,2.99434,11.1315-5.83665,2.44774-3.38892,8.28443,1.90589,7.73785,2.31464,9.91-.80336,17.2438-.80336,17.2438,16.56414-.4182,24.57761,3.92131c0,0-3.81184-15.47572-1.09787-17.78566s5.83669-2.44777,3.79767-6.11231-7.06294-4.0686-1.49717-5.56576,5.409-33.40389,5.409-33.40389Z"
          transform="translate(-192.10319 -162.74578)"
          fill="#2390ea"
        />
        <path
          d="M308.54683,231.74578c-.65.15-1.3.28-1.95.38a27.10211,27.10211,0,0,1-6.01.31,28.36587,28.36587,0,1,1,28.98-35.44c.06.23.12.45.17.68A28.37691,28.37691,0,0,1,308.54683,231.74578Z"
          transform="translate(-192.10319 -162.74578)"
          fill="#ffb6b6"
        />
        <path
          d="M282.36476,228.39815c-3.94555-3.08783-4.32854-8.97352-7.6955-12.68371-5.6294-6.20321-8.27423-12.877-5.32535-20.22729,2.60826-6.5011,2.05387-14.49254,7.67133-18.67721-2.759-.99874-3.40824-3.07282-.97389-3.11112,1.64162-.02582,7.76142,3.11169,4.81671-.79681s2.62942-2.36573,2.26071-3.96561-2.73287-2.486-3.77557-1.2178c3.329-3.90061,10.59878-3.069,12.9609,1.48261,3.318-4.97272,6.84005-8.39442,11.91732-5.23874,4.98668,3.09935,4.92149,6.93058,10.28394,6.00983,2.521-.43283,2.75915,4.33,4.48133,6.2212s4.23965,3.35488,6.753,2.8799,4.43526-3.60718,3.02689-5.7424l5.92379,13.51424,2.38742-2.92841c3.79356,2.47227,2.54757,8.92338-1.271,11.3568s-11.10621-3.191-13.17847.83489c-3.2666,6.34619-10.92969,3.1416-16.19239-1.34327-6.38183-5.439-14.08486,11.09835-11.01065,18.89891-3.18976-3.13222-9.09048-.53286-10.7429,3.621s-.44076,8.83381.78417,13.13321Z"
          transform="translate(-192.10319 -162.74578)"
          fill="#2f2e41"
        />
        <path
          d="M676.52226,321.77291H646.50453a2.9411,2.9411,0,0,1-2.9378-2.9378V289.18359a2.9411,2.9411,0,0,1,2.9378-2.93781h30.01773a2.9411,2.9411,0,0,1,2.9378,2.93781v29.65152A2.9411,2.9411,0,0,1,676.52226,321.77291Z"
          transform="translate(-192.10319 -162.74578)"
          fill="#f2f2f2"
        />
        <path
          d="M681.51975,324.27166H651.502a2.9411,2.9411,0,0,1-2.93781-2.93781V291.68233a2.9411,2.9411,0,0,1,2.93781-2.9378h30.01772a2.94111,2.94111,0,0,1,2.93781,2.9378v29.65152A2.94111,2.94111,0,0,1,681.51975,324.27166Z"
          transform="translate(-192.10319 -162.74578)"
          fill="#f2f2f2"
        />
        <path
          d="M647.13362,287.12108a2.69159,2.69159,0,0,0-2.69158,2.69159V318.206a2.69159,2.69159,0,0,0,2.69158,2.69159h18.355a13.096,13.096,0,0,0,13.096-13.096V289.81267a2.6916,2.6916,0,0,0-2.69159-2.69159Z"
          transform="translate(-192.10319 -162.74578)"
          fill="#fff"
        />
        <path
          d="M669.553,295.738H653.41087a.55849.55849,0,1,1,0-1.117H669.553a.55849.55849,0,0,1,0,1.117Z"
          transform="translate(-192.10319 -162.74578)"
          fill="#f2f2f2"
        />
        <path
          d="M669.553,307.64758H653.41087a.55849.55849,0,1,1,0-1.117H669.553a.55849.55849,0,0,1,0,1.117Z"
          transform="translate(-192.10319 -162.74578)"
          fill="#f2f2f2"
        />
        <path
          d="M676.108,301.69668H646.8559a.55849.55849,0,1,1,0-1.117H676.108a.55849.55849,0,0,1,0,1.117Z"
          transform="translate(-192.10319 -162.74578)"
          fill="#f2f2f2"
        />
        <path
          d="M676.40665,312.49H665.83924a.55849.55849,0,1,1,0-1.117h10.56741a.55849.55849,0,0,1,0,1.117Z"
          transform="translate(-192.10319 -162.74578)"
          fill="#f2f2f2"
        />
        <path
          d="M585.99268,340.33447l-.38965-.19238c-22.77832-11.26123-47.41992-11.251-75.33155.03076l-.749-1.85449c28.31055-11.4419,53.36524-11.49609,76.5752-.16162,32.66308-12.43018,53.66113-33.49707,62.416-62.624l1.916.57618c-8.98047,29.874-30.52344,51.43115-64.03028,64.07226Z"
          transform="translate(-192.10319 -162.74578)"
          fill="#f2f2f2"
        />
        <path
          d="M681.01367,340.33447l-.3916-.18359c-22.38672-10.50293-47.043-10.49317-75.37695.03223l-.69727-1.875c28.69531-10.65967,53.73535-10.71143,76.53027-.15137,36.57129-14.61231,57.56641-35.64258,62.40918-62.51319l1.96875.3545c-4.99218,27.69384-26.53808,49.28613-64.04,64.17675Z"
          transform="translate(-192.10319 -162.74578)"
          fill="#f2f2f2"
        />
        <path
          d="M755.89941,339.27051l-.24707-.10645c-18.44629-7.92968-42.44531-7.91308-75.52148.05371l-.46875-1.94433c33.37109-8.03711,57.70019-8.05664,76.53125-.05274,37.37891-4.5708,57.75586-25.02392,62.28516-62.52l1.98632.24024c-4.668,38.63379-25.70019,59.666-64.29882,64.29736Z"
          transform="translate(-192.10319 -162.74578)"
          fill="#f2f2f2"
        />
        <circle cx="465.79361" cy="109.5" r="8" fill="#2390ea" />
        <circle cx="555.79361" cy="109.5" r="8" fill="#2390ea" />
        <circle cx="627.79361" cy="108.5" r="8" fill="#2390ea" />
        <path
          d="M596.88851,401.27166h-84.0918a8.23923,8.23923,0,0,1-8.23-8.23V309.97576a8.23923,8.23923,0,0,1,8.23-8.23h84.0918a8.23923,8.23923,0,0,1,8.23,8.23v83.06592A8.23923,8.23923,0,0,1,596.88851,401.27166Z"
          transform="translate(-192.10319 -162.74578)"
          fill="#e6e6e6"
        />
        <path
          d="M582.88851,394.27166h-84.0918a8.23923,8.23923,0,0,1-8.23-8.23V302.97576a8.23923,8.23923,0,0,1,8.23-8.23h84.0918a8.23923,8.23923,0,0,1,8.23,8.23v83.06592A8.23923,8.23923,0,0,1,582.88851,394.27166Z"
          transform="translate(-192.10319 -162.74578)"
          fill="#e6e6e6"
        />
        <path
          d="M514.45723,297.19785a21.43841,21.43841,0,0,0-21.43841,21.43841v65.643a7.54022,7.54022,0,0,0,7.54023,7.54022h51.41988a36.68732,36.68732,0,0,0,36.68733-36.68732V304.73808a7.54023,7.54023,0,0,0-7.54023-7.54023Z"
          transform="translate(-192.10319 -162.74578)"
          fill="#fff"
        />
        <path
          d="M563.36477,321.33726H518.14416a1.56455,1.56455,0,1,1,0-3.1291h45.22061a1.56455,1.56455,0,0,1,0,3.1291Z"
          transform="translate(-192.10319 -162.74578)"
          fill="#e6e6e6"
        />
        <path
          d="M563.36477,354.70088H518.14416a1.56456,1.56456,0,1,1,0-3.12911h45.22061a1.56456,1.56456,0,0,1,0,3.12911Z"
          transform="translate(-192.10319 -162.74578)"
          fill="#e6e6e6"
        />
        <path
          d="M581.72791,338.03H499.781a1.56455,1.56455,0,1,1,0-3.1291h81.94689a1.56455,1.56455,0,0,1,0,3.1291Z"
          transform="translate(-192.10319 -162.74578)"
          fill="#e6e6e6"
        />
        <path
          d="M582.56464,368.2665H552.96105a1.56455,1.56455,0,1,1,0-3.1291h29.60359a1.56455,1.56455,0,0,1,0,3.1291Z"
          transform="translate(-192.10319 -162.74578)"
          fill="#e6e6e6"
        />
        <path
          d="M782.52226,317.77291H752.50453a2.9411,2.9411,0,0,1-2.9378-2.9378V285.18359a2.9411,2.9411,0,0,1,2.9378-2.93781h30.01773a2.9411,2.9411,0,0,1,2.9378,2.93781v29.65152A2.9411,2.9411,0,0,1,782.52226,317.77291Z"
          transform="translate(-192.10319 -162.74578)"
          fill="#f2f2f2"
        />
        <path
          d="M787.51975,320.27166H757.502a2.9411,2.9411,0,0,1-2.93781-2.93781V287.68233a2.9411,2.9411,0,0,1,2.93781-2.9378h30.01772a2.94111,2.94111,0,0,1,2.93781,2.9378v29.65152A2.94111,2.94111,0,0,1,787.51975,320.27166Z"
          transform="translate(-192.10319 -162.74578)"
          fill="#f2f2f2"
        />
        <path
          d="M753.13362,283.12108a2.69159,2.69159,0,0,0-2.69158,2.69159V314.206a2.69159,2.69159,0,0,0,2.69158,2.69159h18.355a13.096,13.096,0,0,0,13.096-13.096V285.81267a2.6916,2.6916,0,0,0-2.69159-2.69159Z"
          transform="translate(-192.10319 -162.74578)"
          fill="#fff"
        />
        <path
          d="M775.553,291.738H759.41087a.55849.55849,0,1,1,0-1.117H775.553a.55849.55849,0,0,1,0,1.117Z"
          transform="translate(-192.10319 -162.74578)"
          fill="#f2f2f2"
        />
        <path
          d="M775.553,303.64758H759.41087a.55849.55849,0,1,1,0-1.117H775.553a.55849.55849,0,0,1,0,1.117Z"
          transform="translate(-192.10319 -162.74578)"
          fill="#f2f2f2"
        />
        <path
          d="M782.108,297.69668H752.8559a.55849.55849,0,1,1,0-1.117H782.108a.55849.55849,0,0,1,0,1.117Z"
          transform="translate(-192.10319 -162.74578)"
          fill="#f2f2f2"
        />
        <path
          d="M782.40665,308.49H771.83924a.55849.55849,0,1,1,0-1.117h10.56741a.55849.55849,0,0,1,0,1.117Z"
          transform="translate(-192.10319 -162.74578)"
          fill="#f2f2f2"
        />
        <path
          d="M438.41328,719.92127a2.26935,2.26935,0,0,1-1.64469-3.93333l.1555-.61819q-.03071-.07428-.06179-.1484a6.09416,6.09416,0,0,0-11.24138.04177c-1.83859,4.42817-4.17942,8.86389-4.7558,13.54594a18.02893,18.02893,0,0,0,.31649,6.20047,72.28134,72.28134,0,0,1-6.57515-30.02077,69.766,69.766,0,0,1,.43274-7.78294q.3585-3.17781.99471-6.31034A73.11887,73.11887,0,0,1,430.534,659.90586a19.459,19.459,0,0,0,8.093-8.39652,14.84338,14.84338,0,0,0,1.34991-4.05618c-.394.05168-1.48556-5.94866-1.1884-6.31679-.54906-.83317-1.53178-1.24733-2.13144-2.06035-2.98232-4.0434-7.0912-3.33741-9.2362,2.15727-4.58224,2.31266-4.6266,6.14806-1.815,9.83683,1.78878,2.34681,2.03456,5.52233,3.60409,8.03478-.16151.20671-.32945.40695-.4909.61366a73.59114,73.59114,0,0,0-7.681,12.16859,30.59255,30.59255,0,0,0-1.82641-14.20958c-1.74819-4.21732-5.02491-7.76915-7.91045-11.415a6.27922,6.27922,0,0,0-11.184,3.08333q-.00886.08063-.01731.1612.6429.36267,1.25864.76991a3.0782,3.0782,0,0,1-1.24107,5.60175l-.06277.00967a30.62827,30.62827,0,0,0,.80734,4.57938c-3.70179,14.31578,4.29011,19.52989,15.70147,19.76412.25191.12916.49739.25832.74929.38109a75.11456,75.11456,0,0,0-4.04326,19.02779,71.24446,71.24446,0,0,0,.05167,11.50322l-.01938-.13562a18.82569,18.82569,0,0,0-6.4266-10.87028c-4.94561-4.06263-11.93283-5.55868-17.26826-8.82425a3.533,3.533,0,0,0-5.41121,3.43708l.02182.14261a20.67715,20.67715,0,0,1,2.31871,1.11733q.64288.36276,1.25864.76992a3.07824,3.07824,0,0,1-1.24108,5.6018l-.06282.00964c-.04519.00646-.084.01295-.12911.01944a30.65467,30.65467,0,0,0,5.63854,8.82923c2.31464,12.49712,12.256,13.68282,22.89023,10.04353h.00648a75.09193,75.09193,0,0,0,5.0444,14.72621h18.02018c.06464-.20022.12274-.40693.1809-.60717a20.47436,20.47436,0,0,1-4.9863-.297c1.337-1.64058,2.674-3.29409,4.011-4.93462a1.11749,1.11749,0,0,0,.084-.09689c.67817-.8396,1.36282-1.67283,2.041-2.51246l.00036-.001a29.99038,29.99038,0,0,0-.87875-7.63984Z"
          transform="translate(-192.10319 -162.74578)"
          fill="#f2f2f2"
        />
        <path
          d="M192.10319,736.06422a1.18647,1.18647,0,0,0,1.19007,1.19h253.29a1.19,1.19,0,1,0,0-2.38h-253.29A1.18651,1.18651,0,0,0,192.10319,736.06422Z"
          transform="translate(-192.10319 -162.74578)"
          fill="#ccc"
        />
        <path
          d="M300.58681,232.43578l6.01-.31A27.10211,27.10211,0,0,1,300.58681,232.43578Z"
          transform="translate(-192.10319 -162.74578)"
          fill="#f2f2f2"
        />
        <polygon
          points="144.294 94.03 143.304 95.29 140.644 98.67 117.674 127.88 84.304 93.72 85.634 92.66 92.714 77.61 129.084 75.11 139.964 92.27 144.294 94.03"
          fill="#3f3d56"
        />
      </svg>
      <h2
        class="bottom-text lead text-center d-flex justify-content-center align-items-center mt-4"
        [innerHTML]="'contactManager.contactManager' | translate"
      ></h2>
      <h2>
        <span class="manager-on-duty">
          <img src="assets/images/manager-on-duty.png" alt="Manager on duty" /> MANAGER - ON DUTY
        </span>
      </h2>
      <button
        class="d-flex align-items-center justify-content-center btn btn-outline-secondary mt-3"
        (click)="signOut()"
      >
        <svg class="logout-icon mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path
            d="M160 96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96C43 32 0 75 0 128V384c0 53 43 96 96 96h64c17.7 0 32-14.3 32-32s-14.3-32-32-32H96c-17.7 0-32-14.3-32-32l0-256c0-17.7 14.3-32 32-32h64zM504.5 273.4c4.8-4.5 7.5-10.8 7.5-17.4s-2.7-12.9-7.5-17.4l-144-136c-7-6.6-17.2-8.4-26-4.6s-14.5 12.5-14.5 22v72H192c-17.7 0-32 14.3-32 32l0 64c0 17.7 14.3 32 32 32H320v72c0 9.6 5.7 18.2 14.5 22s19 2 26-4.6l144-136z"
          />
        </svg>
        {{ 'common.signOut' | translate }}
      </button>
    </div>
  </div>
</div>
